import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import React from "react"
import Layout from "../components/layout"
import HeroHeadline from "../components/heroheadline"
import * as hero from '../styles/hero.module.css'
import * as styles from '../styles/oer.module.css'
import Sectionheader from "../components/sectionheader"

export default function Technology() {
    const bannerOer = useStaticQuery(graphql`
        query OerHero {
            file(relativePath: {eq: "heroes/AdobeStock_372313087.jpeg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        },
    `)

    return(
        <Layout>
            <div className={hero.smarthero}>
                <Img fluid={bannerOer.file.childImageSharp.fluid} className={hero.smartheroimage}/>
                <HeroHeadline h1="Open Educational Resources (OER)" sub="Education for All" />
            </div>
            <div className={styles.who}>
                <div className="container">
                    <div className="row align-items-start">
                        <Sectionheader name="About OER" />
                        <div className="col-11 col-sm-8">
                            <p>Open Educational Resources (OER) are education materials that can be freely downloaded, edited, and shared to better serve all students. Research demonstrates that students enrolled in courses where OER are used in lieu of traditional course materials save money and perform just as well, if not better, than control groups enrolled in courses with traditional materials. Faculty and students have 24-7 access, anytime, and anywhere in the world with internet access to educational content, textbooks, instructional tools, and free and low-cost materials. </p>
                        </div>
                        <div className="col-11 col-sm-3">
                            <div className={styles.whocontact}>
                                <ul>
                                    <li>
                                        <a href="mailto:smartcenter@tnstate.edu" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">Contact Us</a>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div className={styles.whocontact}>
                                <ul>
                                    <li>
                                        <a href="https://ai-tnstatesmartcenter.org/new-page-2-2-2-2-2-2" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">Learn More</a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.resources}>
                        <div className="row" data-masonry='{"percentPosition": true }'>
                        <div className="container">
                        <div className={styles.cardholder}>
                            <div className="row justify-content-evenly">
                                <div className="col-3"><a href="https://tsu-excel4ed.merlot.org" className="btn btn-primary btn-lg" target="_blank" rel="noreferrer">TSU OER Resources</a></div>
                                <div className="col-3"><a href="https://www.tn.gov/thec/bureaus/academic-affairs-and-student-success/textbook.html" className="btn btn-primary btn-lg" target="_blank" rel="noreferrer">Tennessee Textbook Affordability</a></div>
                                <div className="col-3"><a href="http://www.hbcuals.org/" className="btn btn-primary btn-lg" target="_blank" rel="noreferrer">HBCU Affordable Learning Solutions</a></div>
                            </div></div>
                            <div className={styles.cardholder}>
                            <div className="row justify-content-evenly">
                                <div className="col-3"><a href="https://www.merlot.org/merlot/" className="btn btn-primary btn-lg" target="_blank" rel="noreferrer">MERLOT OER Global Search</a></div>
                                <div className="col-3"><a href="http://www.hbcuals.org/cultural_collections.html" className="btn btn-primary btn-lg" target="_blank" rel="noreferrer">Cultural/Diversity/Social Justice OER</a></div>
                                <div className="col-3"><a href="https://www.skillscommons.org/" className="btn btn-primary btn-lg" target="_blank" rel="noreferrer">OER Skills/Careers/Workforce</a></div>
                            </div></div>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </Layout>
    )
}